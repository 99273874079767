<template>
  <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="150" cy="150" r="150" fill="#F3F3F4"/>
  <g clip-path="url(#clip0_7689_308097)">
  <path d="M148.922 165.108H176.98V137.05H148.922V116.007L113.851 151.079L148.922 186.151V165.108ZM198.023 87.9497H168.703C165.757 79.813 158.041 73.9209 148.922 73.9209C139.804 73.9209 132.088 79.813 129.142 87.9497H99.8217C98.8397 87.9497 97.9279 88.0198 97.016 88.2303C94.782 88.699 92.6954 89.7036 90.9359 91.1577C89.1763 92.6118 87.7965 94.4717 86.9153 96.5774C86.2138 98.1907 85.793 100.014 85.793 101.978V200.18C85.793 202.074 86.2138 203.968 86.9153 205.651C87.6167 207.335 88.6689 208.808 89.9315 210.14C91.8253 212.034 94.2804 213.437 97.016 213.998C97.9279 214.139 98.8397 214.209 99.8217 214.209H198.023C205.739 214.209 212.052 207.896 212.052 200.18V101.978C212.052 94.2626 205.739 87.9497 198.023 87.9497ZM148.922 86.1961C151.798 86.1961 154.183 88.581 154.183 91.4569C154.183 94.3328 151.798 96.7177 148.922 96.7177C146.047 96.7177 143.662 94.3328 143.662 91.4569C143.662 88.581 146.047 86.1961 148.922 86.1961ZM198.023 200.18H99.8217V101.978H198.023V200.18Z" :fill="color || '#015CA1'"/>
  <circle cx="203.129" cy="97.3717" r="30.4653" fill="#F5831F"/>
  <rect x="199.164" y="81.3123" width="8.63929" height="32.8293" rx="4.31964" fill="#F4F4F4"/>
  <rect x="219.898" y="93.4077" width="8.63929" height="32.8293" rx="4.31964" transform="rotate(90 219.898 93.4077)" fill="#F4F4F4"/>
  </g>
  <defs>
  <clipPath id="clip0_7689_308097">
  <rect width="168.345" height="168.345" fill="white" transform="translate(64.75 66.9065)"/>
  </clipPath>
  </defs>
  </svg>
</template>

<script>
export default {
  name: 'Empty',
  props: ['color'],
}
</script>
