<template>
  <div class="biz-container">
    <div class="ant-modal-root order-detail_loader" v-if="loading">
      <div class="ant-modal-mask">
        <div
          class="ant-modal-wrap d-flex justify-content-center align-items-center"
        >
          <a-spin></a-spin>
        </div>
      </div>
    </div>
    <div @scroll="isBottom" id="list-purchase" v-if="!firstLoading">
      <div class="d-flex col pr-0 justify-content-between mb-3">
        <div class="d-flex container-button-type gap-12 justify-content-between align-items-center mb-3">
          <h2 class="mb-5">{{$t('menu.profile.retur.title')}}</h2>
        </div>
        <div class="d-flex container-button-type gap-12 justify-content-between align-items-center mb-3">
          <div
            @click="handleModalSaldo"
            class="btn rounded btn-danger align-self-end align-items-center justify-content-between mb-3"
            style="background-color: white; border: solid 1px var(--biz-brand-button); color: var(--biz-brand-button) !important"
          >
            <div>
              <img src="@/resources/images/return.png" style="filter: brightness(0%);"/>
              <span class="mx-2">{{ $t('menu.retur.list.return_balance') }}: Rp. <span v-if="loadingBalance"><a-spin/></span><span v-else>{{balance}}</span></span>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Choose Distributor -->
      <b-modal id="modal-response" v-model="modalDistributor" centered class="p-3" hide-footer>
        <div slot="modal-header" class="d-flex align-items-center justify-content-between p-2 w-100">
          <span class="text-header-modal-distributor mb-0">Pilih Distributor</span>
          <span @click="modalDistributor = false" class="mb-0" style="cursor: pointer;">
            <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.2246 5.0175L15.7423 12.499L23.2246 19.9805C24.2553 21.0125 24.2553 22.6877 23.2273 23.7226C22.1939 24.76 20.5209 24.7574 19.4875 23.7253L12 16.2385L4.51247 23.7253C3.47909 24.7574 1.80609 24.76 0.772705 23.7226C-0.258011 22.6879 -0.258013 21.0127 0.775369 19.9805L8.25769 12.499L0.775369 5.0175C-0.258013 3.98543 -0.258011 2.31023 0.772705 1.27536C1.28813 0.756718 1.96732 0.5 2.64398 0.5C3.32065 0.5 3.99717 0.759386 4.5126 1.27282L12.0001 8.75964L19.4876 1.27282C20.0031 0.756716 20.6796 0.5 21.3562 0.5C22.0329 0.5 22.7121 0.759386 23.2275 1.27536C24.258 2.31023 24.258 3.98543 23.2246 5.0175Z" fill="var(--biz-brand-button)"/>
            </svg>
          </span>
        </div>
        <div v-for="({ name, id }, index) in listDistributor" :key="id" class="mb-3">
          <a-radio-group
            class="w-100"
            v-model="selectedDistributor"
          >
          <div @click="selectedDistributor = id" style="cursor: pointer;" class="d-flex container-button-type gap-12 justify-content-between align-items-center">
            <div class="d-flex align-items-center pl-3">
              <WarehousePinIcon color="var(--biz-secondary-100)"/>
              <div class="warehouse-name ml-2"> {{ name }} </div>
            </div>
            <div class="d-flex align-items-center mr-3">
              <div class="radio-button-distributor">
                <a-radio
                  :value="id"
                />
              </div>
            </div>
          </div>
          <a-divider class="my-2" v-if="index + 1 !== listDistributor.length"/>
          </a-radio-group>
        </div>
        <a-col :xs="24" :sm="24" :md="12">
          <a-button
            class="mt-3"
            style="
              width: 95%;
              color: var(--biz-brand-button) !important;
              border: 1px solid var(--biz-brand-button) !important;
            "
            @click.prevent="() => {
              modalDistributor = false
              selectedDistributor = null
            }"
          >
            Batal
          </a-button>
        </a-col>

        <a-col :xs="24" :sm="24" :md="12">
          <a-button
            @click.prevent="() => toCreateRetur()"
            type="primary"
            block
            :disabled = "!selectedDistributor"
            class="mt-3"
            style="
              width: 95%;
              background: var(--biz-brand-button) !important;
              color: #FFFFFF !important;
              border: 1px solid var(--biz-brand-button) !important;
            "
          >
            Pilih
          </a-button>
        </a-col>
      </b-modal>

      <div class="d-flex col pr-0 justify-content-between mb-3">
        <div class="d-flex container-button-type gap-12 justify-content-between align-items-center mb-3">
          <div v-for="({ title, key }) in listTab" :key="key">
            <div v-if="key === activeKey" class="btn active flex ml-1 w-auto btn-order-state" @click="changeType(key)">{{ title }}</div>
            <div v-else class="btn inactive flex ml-1 w-auto" @click="changeType(key)">{{ title }}</div>
          </div>
        </div>
        <div @click="handleModalDistributor(true)" class="btn d-flex rounded align-self-end align-items-center mb-3">
          <span class="ml-2"> + {{ $t('menu.retur.list.return_request') }}</span>
        </div>
      </div>

      <div class="d-flex pr-0 justify-content-center align-items-center flex-column pt-5"
        v-if="!loading && data.length === 0">
        <div v-if="activeKey === 0" class="mt-3 ml-2 h4 align-items-center d-flex flex-column">
          <div class="d-flex align-items-center mb-4">
            <EmptyIcon :color="'var(--biz-primary-100)'"/>
          </div>
          <span style="color: var(--biz-brand-button)" class="ml-2 desc-empty-state">
            {{ $t('menu.retur.list.return_empty') }}
          </span>
          <div class="card-footer border-top-0 mt-1">
            <button @click="handleModalDistributor(true)" class="btn w-100 button-empty-state">
              + {{ $t('menu.retur.list.return_request') }}
            </button>
          </div>
        </div>
        <div v-else class="w-35 d-flex align-items-center flex-column pb-5">
          <div class="d-flex align-items-center mb-4">
            <EmptyIcon :color="'var(--biz-primary-100)'"/>
          </div>
          <span style="color: var(--biz-brand-button)" class="ml-2 desc-empty-state">
            {{ $t('menu.retur.list.return_empty') }}
          </span>
          <div class="card-footer border-top-0 mt-1">
            <button @click="handleModalDistributor(true)" class="btn w-100 button-empty-state">
              + {{ $t('menu.retur.list.return_request') }}
            </button>
          </div>
        </div>
      </div>
      <div v-else id="wrap" class="col pr-0"
        v-for="({ firstItem, theRestItem, status, createdAt, newTotal, id, title, image_url, return_number }, index) in data"
        :key="index">
        <div class="card col-12 d-flex justify-content-center">
          <div class="card-header d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="h6 mr-2" style="color:black">{{ return_number }}</div>
              <div class="h6" style="color:#929292">{{ createdAt }}</div>
            </div>
            <div class="btn status text-white tag-status" :style="{ background: bgColor[status].bg, color: bgColor[status].color }">
              {{ title }}
            </div>
          </div>
          <div class="card-body d-flex justify-content-between align-items-end">
            <div class="d-flex">
              <div v-if="image_url" class="mr-3">
                <img :src="image_url" loading="lazy" style="max-width: 100%; width: 95px" />
              </div>
              <div class="customBox">
                <span class="title-retur-primary">{{ firstItem && firstItem.catalog_title }}</span>
                <span class="title-retur-grey">{{ `${firstItem && firstItem.requested_item_qty} ${firstItem && firstItem.item_uom}` }}</span>
                <span v-if="theRestItem">{{ `+${theRestItem} Produk lainya` }}</span>
              </div>
            </div>
            <div>
              <span class="title-retur-primary">{{ $t('menu.retur.list.estimated_refund') }}</span> : <span class="h5" style="color: #F5831F;">Rp. {{ newTotal }}</span>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-end">
            <span  v-if="status === 'SHIPPING'" style="color: var(--biz-brand-button); border: 1px solid #015289" class="btn action-button"
              @click.stop="openModal(id)">{{ $t('menu.retur.list.order_receive') }}</span>

            <div v-if="status === 'DRAFT'" class="delete-btn" @click.stop="handleDeleteModal(id)">
              <a-icon type="delete" />
              {{ $t('purchase.cart.delete') }}
            </div>
            <button v-if="status === 'DELIVERED'" disabled style="color: #ABABAB; border: 1px solid #ABABAB"
              class="btn disabled action-button" @click.stop="() => undefined">
              <span>
                <i class="fe fe-clock"></i>
              </span>
              <span>
                {{ $t('menu.retur.list.detail_request_retur') }}
              </span>
            </button>
            <span style="color: white; border: 1px solid #015289; background-color: var(--biz-brand-button);" class="btn ml-3 w-auto action-button"
              @click.stop="status === 'DRAFT' ? showDraft(id) : showDetail(return_number)">{{ $t('menu.retur.list.return_request_detail') }}</span>
          </div>
        </div>
      </div>
      <a-pagination
        :current="page"
        :pageSize="limit"
        :total="total"
        v-if="data.length > 0"
        @change="onChangePagination"
        style="text-align: right;"
      />
    </div>

    <b-modal id="modal-delete-retur" v-model="showModalDelete" centered class="p-3" hide-footer :hide-header="true" size="400" @hide="showModalDelete = false">
      <template v-if="!loading">
        <div class="pt-3">
          <div class="container-title d-flex flex-column align-items-center justify-content-center mb-4" style="gap: 10px 0px;">
            <Trash2 style="width: 130px"/>
            <p class="title-text">Hapus</p>
            <p class="desc-text">Anda yakin ingin menghapus permohonan retur ? </p>
          </div>
          <a-row :gutter="[16, 16]">
            <a-col :xs="24" :sm="24" :md="12">
              <a-button
                class="mt-3 cancel-btn"
                @click.prevent="showModalDelete = false"
              >
                Batal
              </a-button>
            </a-col>
            <a-col :xs="24" :sm="24" :md="12">
              <a-button
                @click.prevent="() => handleDeleteReturn(selectedIdReturn)"
                type="primary"
                block
                class="mt-3 accept-btn"
              >
                Ya
              </a-button>
            </a-col>
          </a-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import EmptyIcon from '@/components/Icons/EmptyRetur.vue'
import WarehousePinIcon from '@/components/Icons/WarehousePin.vue'
import Trash2 from '@/components/Icons/Trash2.vue'

export default {
  name: 'component-purchase-index',
  components: {
    EmptyIcon,
    WarehousePinIcon,
    Trash2,
  },
  data: function () {
    return {
      bgColor: {
        DRAFT: {
          bg: '#FFC700',
          color: '#000000',
        },
        COMPLETED: {
          bg: 'var(--biz-brand-button)',
          color: '#ffffff',
        },
        CANCELED: {
          bg: '#FF0000',
          color: '#ffffff',
        },
        REJECTED: {
          bg: '#FF0000',
          color: '#ffffff',
        },
        VERIFIED: {
          bg: '#929292',
          color: '#ffffff',
        },
        REQUESTED: {
          bg: '#929292',
          color: '#ffffff',
        },
        ACCEPTED: {
          bg: 'var(--biz-brand-button)',
          color: '#ffffff',
        },
        SUBMITTED: {
          bg: '#929292',
          color: '#ffffff',
        },
        DELIVERED: {
          bg: '#F5831F',
          color: '#ffffff',
        },
        REQUESTED: {
          bg: '#929292',
          color: '#ffffff',
        },
        ACKNOWLEDGED: {
          bg: '#f58320',
          color: '#ffffff',
        },
      },
      listTab: [
        {
          title: 'Semua',
          key: 0,
          value: ['DRAFT,REQUESTED,ACKNOWLEDGED,ACCEPTED,REJECTED,CANCELED'],
          state: ['DRAFT,REQUESTED,ACKNOWLEDGED,ACCEPTED,REJECTED,CANCELED'],
        },
        {
          title: 'Draft',
          key: 1,
          value: ['DRAFT'],
          state: ['DRAFT'],
        },
        {
          title: 'Pengajuan',
          key: 2,
          value: ['REQUESTED'],
          state: ['REQUESTED'],
        },
        {
          title: 'Dalam Proses',
          key: 3,
          value: ['ACKNOWLEDGED'],
          state: ['ACKNOWLEDGED'],
        },
        {
          title: 'Selesai',
          key: 4,
          value: ['COMPLETED','ACCEPTED'],
          state: ['COMPLETED','ACCEPTED'],
        },
        {
          title: 'Ditolak',
          key: 5,
          value: ['CANCELED', 'REJECTED'],
          state: ['CANCELED', 'REJECTED'],
        },
      ],
      listDistributor: [],
      data: [],
      page: 1,
      loading: true,
      loadingBalance: true,
      activeKey: 0,
      modalVisible: false,
      confirmedStatusById: '',
      total_waiting_payment: 0,
      limit: 20,
      total: 0,
      modalSaldo: false,
      modalDistributor: false,
      selectedDistributor: null,
      images_url: {},
      balance: 0,
      listBalance: [],
      draftRetur: {},
      firstLoading: true,
      showModalDelete: false,
      selectedIdReturn: '',
      currentPage: 0,
    }
  },
  computed: {
    ...mapState(['purchase', 'apps',]),
  },
  methods: {
    onChangePagination (page, limit) {
      this.page = page
      this.limit = limit
      const getValueByKey = this.listTab.find(({ key }) => key === 0)
      this.fetchRetur(getValueByKey?.value)
    },
    onShowSizeChange (current, limit) {
      this.currentPage = current
      this.limit = limit
    },
    handleDeleteModal(returnId) {
      this.selectedIdReturn = returnId
      this.showModalDelete = true
    },
    handleDeleteReturn(returnId) {
      this.$store.dispatch('retur/ONDELETERETUR', {
        channel_id: this.$store.state.user.user_data.channel_id,
        return_id: returnId,
      })
        .then(() => {
          this.showModalDelete = false
          this.draftRetur = {}
          const getValueByKey = this.listTab.find(({ key }) => key === 0)
          this.fetchRetur(getValueByKey?.value)
          this.fetchReturDraft()
          this.$notification.success({
            message: this.$t('menu.retur.list.delete_success_info'),
          })
        })
    },
    handleModalDistributor(val) {
      if (this.draftRetur.id) {
        this.$router.push({ path: `/account/retur/create/${this.draftRetur.id}` })
      } else {
        this.modalDistributor = val
      }
    },
    changeType(key) {
      this.activeKey = key
      this.page = 1
      this.limit = 20
      const getValueByKey = this.listTab.find(item => item.key === key)
      this.fetchRetur(getValueByKey?.value)
    },
    openModal(id) {
      this.confirmedStatusById = id
      this.modalVisible = true
    },
    handleModalSaldo() {
      this.$router.push({ path: `/account/retur/history` })
    },
    goToHome() {
      this.$router.push('/dashboard')
    },
    showDetail(param) {
      this.$router.push({ path: `/account/retur/detail/${param}` })
    },
    showDraft(param) {
      this.$router.push({ path: `/account/retur/create/${param}` })
    },
    toCreateRetur() {
      this.$router.push({ path: `/account/retur/create/${this.selectedDistributor}` })
    },
    async fetchReturDraft() {
      this.$store.dispatch('retur/GETRETUR', {
        status: 'DRAFT',
        page: this.page,
        limit: this.limit,  
        channel_id: this.$store.state.user.user_data.channel_id,
        buyer_id: this.$store.state.user.user_data.buyer_id,
      })
        .then(({ data }) => {
          if (data.contents.length > 0) {
            this.draftRetur = data.contents[0]
          }
        })
        .catch((err) => {
        })
    },
    async fetchRetur(status, moreFetch) {
      this.loading = true
      this.$store.dispatch('retur/GETRETUR', {
        status,
        page: this.page,
        limit: this.limit,  
        channel_id: this.$store.state.user.user_data.channel_id,
        buyer_id: this.$store.state.user.user_data.buyer_id,
      })
        .then(({ data }) => {
          const dataContents = data.contents
          this.total = data.total_elements
          this.currentPage = data.total_elements
          const promises = []
          let newData = (dataContents || [])?.map(({ created_at, ...rest }) => {
            let image_url;
            if (rest.return_items[0]) {
              promises.push(
                this.$store.dispatch('product/GETPRODUCTDETAIL', {
                  product_id: rest.return_items[0].product_id,
                  channel_id: this.$store.state.user.user_data.channel_id,
                })
              )
            }

            return {
              ...rest,
              createdAt: moment(created_at).format('DD MMMM YYYY'),
              firstItem: rest.return_items[0] || {},
              theRestItem: rest.return_items && rest.return_items.length - 1 || '',
              newTotal: Intl.NumberFormat('en-US').format(rest.total_amount_requested),
              title: this.listTab.find(item => item.state.includes(rest.status))?.title,
            }
          })

          Promise.all(promises)
            .then((data) => {
              let images_url = this.images_url;
              data.map((item) => {
                images_url = {
                  ...images_url,
                  [item.catalogs[0].product_id]: item.showimg_url,
                }
              })

              newData = newData.map((item) => {
                return {
                  ...item,
                  image_url: images_url[item.firstItem.product_id],
                }
              })
              this.data = newData
              this.loading = false
              this.firstLoading = false
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            })
        })
        .catch((err) => {
          this.loading = false
          this.firstLoading = false
        })
    },
    fetchBalanceReturn() {
      this.loadingBalance = true
      this.$store.dispatch('retur/GETBALANCERETURN', {})
        .then((data) => {
          let balance = 0
          let listBalance = data.data
          data.data.map((item) => {
            balance += item.balance
          })
          this.balance = Intl.NumberFormat('en-US').format(balance)

          if(this.$store.state.user.user_data.seller?.length || this.$store.state.account.detailCustomer.distributor?.length) {
            this.$store.dispatch('warehouse/GETWAREHOUSE_SELLER', {
              channel_id: this.$store.state.user.user_data.channel_id,
              seller_id: this.$store.state.user.user_data.seller.length > 0 ? this.$store.state.user.user_data.seller.map((item) => item.id) : this.$store.state.account.detailCustomer.distributor.map((item) => item.seller_id)
            })
              .then(({ data }) => {
                this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
                  id: data.map((item) => item.warehouse_id),
                })
                  .then(({ data }) => {
                    this.listDistributor = data
                    this.listBalance = listBalance.map((balance) => {
                      const sellerIdDetail = data.find((obj) => obj.seller_id === balance.seller_id)
                      return {
                        ...balance,
                        ...sellerIdDetail,
                      }
                    })
                    this.loadingBalance = false
                  })
                  .catch(err => {
                    this.loadingBalance = false
                    console.log(err)
                  })
              })
          } else {
            this.$store.dispatch('warehouse/GETSELLERLIST', {
              q: this.$store.state.user.user_data.area_id,
              page: 1,
              size: 50,
            })
              .then(({ data }) => {
                this.listDistributor = data.records
                this.loadingBalance = false
              })
              .catch(err => {
                this.loadingBalance = false
                console.log(err)
              })
          }
        })
        .catch((err) => {
          this.loadingBalance = false
        })
    },
    fetchListDistributor() {
      const listSellerId = this.$store.state.user.user_data?.seller?.map((obj) => obj.id)
      if (listSellerId?.length > 0) {
        this.$store.dispatch('warehouse/GETWAREHOUSE_SELLER', {
          channel_id: this.$store.state.user.user_data.channel_id,
          seller_id: listSellerId,
        })
          .then(({ data }) => {
            this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
              id: data.map((item) => item.warehouse_id),
            })
              .then(({ data }) => {
                this.listDistributor = data
              })
              .catch((err) => {
                this.$notification.error({
                  message: err.response.statusText,
                })
              })
          })
          .catch((err) => {
            this.$notification.error({
              message: err.response.statusText,
            })
          })
      }
    },
    isBottom() {
      const el = document.querySelectorAll("#wrap")
      const navbarHeight = 151;
      const offsetElement = 5
      const lastEl = el[el.length - offsetElement]
      const bounds = lastEl.getBoundingClientRect().y
      const alreadyGetAllData = this.data.length >= this.total
      if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
        const getValueByKey = this.listTab.find(({ key }) => key === this.activeKey)
        this.fetchRetur(getValueByKey?.value)
      }
    },
  },
  mounted() {
    const getValueByKey = this.listTab.find(({ key }) => key === 0)
    this.fetchRetur(getValueByKey?.value)
    this.fetchReturDraft()
    this.fetchBalanceReturn()
    // this.fetchListDistributor()
    // window.addEventListener("scroll", this.isBottom);
  },
  destroyed() {
    // window.removeEventListener("scroll", this.isBottom);
  },
}
</script>

<style lang="scss" scoped>
.btn-order-state {
  border-radius: 8px;
  color: $white !important;
  border: 1px solid transparent;
}
.active {
  background: var(--biz-brand-button) !important;
  border: 1px solid var(--biz-brand-button) !important;
  border-radius: 12px;
  color: var(--biz-brand-base);
}

.inactive {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  color: #929292;
}

.customBox {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.status:hover {
  cursor: auto !important;
}

.thumbnail {
  cursor: pointer;
}

.container-button-type {
  overflow-x: hidden;
  overflow: hidden;;
}

.action-button {
  border-radius: 8px;
  padding: 5px 40px;
}

.status-tag {
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 20px;
}

.desc-empty-state {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.desc-empty-filter {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}
.button-empty-state {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  padding: 10px 60px;
  color: $black;
  border: 2.25px solid $black;
  border-radius: 6.75px;
}
.tag-status {
  border-radius: 16px;
  padding: 5px 12px;
  font-size: 14px;
}
.title-retur-primary {
  color: #000000;
  size: 14px;
}
.title-retur-grey {
  color: #929292;
  size: 14px;
}
.delete-btn {
  line-height: 35px;
  font-weight: 600;
  color: #ff0000;
  cursor: pointer;
}
.text-header-modal-distributor {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.radio-button-distributor {
  background: #f6f6f6;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  .ant-radio-wrapper {
    margin-right: 0px;
  }
}
.title-text {
  margin: 0px;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.desc-text {
  margin: 0px;
  color: #000000;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}
.cancel-btn {
  width: 100%;
  color: #265C9B !important;
  border: 1px solid #265C9B !important;
  border-radius: 12px !important;
}
.accept-btn {
  width: 100%;
  background: #265C9B !important;
  color: #FFFFFF !important;
  border: 1px solid #265C9B !important;
  border-radius: 12px !important;
}
</style>
